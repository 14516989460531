export const getAllStudents = async id => {
  return await fetch(
    `${process.env.REACT_APP_URL}/api/instructor/student/${id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  ).then(res => {
    return res
  })
}
