import React from "react"
import { Tr, Th, Td } from "react-super-responsive-table"
import { Link } from "react-router-dom"

function StudentCard({ student }) {
  return (
    <Tr>
      <Th>{student?.fullName}</Th>
      <Td>{student?.email}</Td>
      <Td>{student?.phoneNumber}</Td>
    </Tr>
  )
}

export default StudentCard
