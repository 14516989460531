import React, { useState, useEffect } from "react"
import CurriculumSection from "./CurriculumSection"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

const CourseCurriculum = ({ setAddStatus, addStatus, id, chapters }) => {
  const [sections, setSections] = useState([])
  const [sectionName, setSectionName] = useState("")

  useEffect(() => {
    setSections(chapters)
  }, [chapters])

  const handleSubmit = e => {
    e.preventDefault()
    console.log("click")

    const body = {
      chapter_title: sectionName,
      chapter_duration: 20,
    }
    fetch(`${process.env.REACT_APP_URL}/api/chapter/post/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then(res => res.json())
      .then(data => {
        toastr.success(data?.message, "Section")
        setAddStatus(!addStatus)
      })

    // setSections(prevSection => [...prevSection, { chapter_title: sectionName }])
    setSectionName("")
  }

  /**
   *
   * * Delete Section
   */
  const handleDeleteSection = sectionId => {
    fetch(`${process.env.REACT_APP_URL}/api/chapter/delete/${sectionId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id }),
    })
      .then(res => res.json())
      .then(data => {
        toastr.success(data?.message, "Section")
        setAddStatus(!addStatus)
      })
  }

  return (
    <div>
      <label>Course Curriculum</label>
      <div className="curriculum">
        {sections?.length > 0 &&
          sections?.map((sec, i) => (
            <CurriculumSection
              handleDeleteSection={handleDeleteSection}
              key={i}
              sec={sec}
              courseId={id}
              addStatus={addStatus}
              setAddStatus={setAddStatus}
            />
          ))}
        <form onSubmit={handleSubmit}>
          <div className="curriculum__input">
            <span>
              <i className="bx bx-plus"></i>
            </span>
            <input
              type="text"
              value={sectionName}
              onChange={e => setSectionName(e.target.value)}
              placeholder="Create new section"
            />
          </div>
        </form>
      </div>
    </div>
  )
}

export default CourseCurriculum
