import React from "react"
import { Redirect } from "react-router-dom"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Login2 from "../pages/AuthenticationInner/Login2"
import Register1 from "../pages/AuthenticationInner/Register"
import Register2 from "../pages/AuthenticationInner/Register2"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import Recoverpw2 from "../pages/AuthenticationInner/Recoverpw2"
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword"
import ForgetPwd2 from "../pages/AuthenticationInner/ForgetPassword2"
import ConfirmMail from "../pages/AuthenticationInner/page-confirm-mail"
import ConfirmMail2 from "../pages/AuthenticationInner/page-confirm-mail-2"
import EmailVerification from "../pages/AuthenticationInner/auth-email-verification"
import EmailVerification2 from "../pages/AuthenticationInner/auth-email-verification-2"
import TwostepVerification from "../pages/AuthenticationInner/auth-two-step-verification"
import TwostepVerification2 from "../pages/AuthenticationInner/auth-two-step-verification-2"
import UserProfile from "../pages/Authentication/user-profile"
// Dashboard
import Dashboard from "../pages/Dashboard/index"

// Student
import Student from "../pages/Students/index"

// Student
import Courses from "../pages/Courses/index"
import AddNewCourse from "../pages/Courses/AddNewCourse"
import EditCourse from "../pages/Courses/EditCourse"

//Instructors
import Instructors from "../pages/Instructors/index"
import AddNewInstructor from "../pages/Instructors/AddNewInstructor"

// Category
import Category from "../pages/Category/Category"
import AddNewCategory from "../pages/Category/AddNewCategory"

// Book
import Book from "../pages/Book/index"
import AddNewBook from "../pages/Book/AddNewBook"
import EditBook from "../pages/Book/EditBook"
import OrderList from "pages/OrderList/OrderList"

const userRoutes = [
  { path: "/dashboard", component: Dashboard },

  // Student
  { path: "/students", component: Student },

  // Instructor
  { path: "/instructors", component: Instructors },
  { path: "/instructors/add/new", component: AddNewInstructor },

  // Order
  { path: "/orders", component: OrderList },
  //{ path: "/instructors/add/new", component: AddNewInstructor },

  // Category
  { path: "/category", component: Category },
  { path: "/category/add/new", component: AddNewCategory },
  // Course
  { path: "/courses", component: Courses },
  { path: "/courses/add/new", component: AddNewCourse },
  { path: "/courses/edit/:id", component: EditCourse },

  //Book
  { path: "/book", component: Book },
  { path: "/book/add/new", component: AddNewBook },
  { path: "/book/edit/:id", component: EditBook },

  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },

  // Authentication Inner
  { path: "/pages-login", component: Login1 },
  { path: "/pages-login-2", component: Login2 },
  { path: "/pages-register", component: Register1 },
  { path: "/pages-register-2", component: Register2 },
  { path: "/page-recoverpw", component: Recoverpw },
  { path: "/page-recoverpw-2", component: Recoverpw2 },
  { path: "/pages-forgot-pwd", component: ForgetPwd1 },
  { path: "/auth-recoverpw-2", component: ForgetPwd2 },
  { path: "/page-confirm-mail", component: ConfirmMail },
  { path: "/page-confirm-mail-2", component: ConfirmMail2 },
  { path: "/auth-email-verification", component: EmailVerification },
  { path: "/auth-email-verification-2", component: EmailVerification2 },
  { path: "/auth-two-step-verification", component: TwostepVerification },
  { path: "/auth-two-step-verification-2", component: TwostepVerification2 },
]

export { userRoutes, authRoutes }
