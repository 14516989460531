import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
    Card, CardBody, Col,
    Container, Form,
    Input, Label, Row
} from "reactstrap"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"

//redux

const EditBook = props => {
  const { id } = useParams()
  const [bookName, setBookName] = useState(null)
  const [bookShortDetails, setBookShortDetails] = useState(null)
  const [bookDetails, setBookDetails] = useState(null)
  const [writerName, setWriterName] = useState(null)
  const [bookCover, setBookCover] = useState(null)
  const [bookFile, setBookFile] = useState(null)
  const [authorDetails, setAuthorDetails] = useState(null)
  const [authorImage, setAuthorImage] = useState(null)

  const history = useHistory()
  const [getBookDetails, setGetBookDetails] = useState([])
  const [uploadStatus, setUploadStatus] = useState(false)

  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/api/book/get/${id}`, { method: "GET" })
      .then(res => res.json())
      .then(data => setGetBookDetails(data?.message))
      .catch(err => console.log(err))
    // setAddStatus(false)
  }, [id])

  useEffect(() => {
    setBookName(getBookDetails?.book_name)
    setBookShortDetails(getBookDetails?.book_short_details)
    setBookDetails(getBookDetails?.book_details)
    setWriterName(getBookDetails?.author_name)
    setBookCover(getBookDetails?.book_cover)
    setBookFile(getBookDetails?.book_file)
    setAuthorDetails(getBookDetails?.author_description)
    setAuthorImage(getBookDetails?.author_image)
  }, [getBookDetails])

  console.log(getBookDetails)

  const handleSubmit = async e => {
    console.log({
      bookName,
      bookShortDetails,
      bookDetails,
      writerName,
      bookCover,
      bookFile,
    })
    e.preventDefault()
    await fetch(`${process.env.REACT_APP_URL}/api/book/update/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        book_name: bookName,
        book_short_details: bookShortDetails,
        book_details: bookDetails,
        author_name: writerName,
        book_cover: bookCover,
        book_file: bookFile,
        author_image: authorImage,
        author_description: authorDetails,
      }),
    })
      .then(res => res.json())
      .then(data => {
        if (!data?.success) {
          toastr.error(data?.message)
        } else if (data?.success) {
          toastr.success(data?.message)
          history.push("/book")
        }
        console.log(data?.message)
      })
      .catch(err => console.log(err))
  }

  const uploadFile = async e => {
    const formData = new FormData()
    formData.append("image", e.target.files[0])

    return await fetch(`${process.env.REACT_APP_URL}/api/book/upload`, {
      method: "POST",
      body: formData,
    }).then(res => {
      return res
    })
  }

  const handleBookCoverChange = e => {
    setUploadStatus(true)
    uploadFile(e, setBookCover)
      .then(res => res.json())
      .then(data => {
        setBookCover(data?.key)
        setUploadStatus(false)
      })
  }

  const handleBookFileChange = e => {
    setUploadStatus(true)
    uploadFile(e)
      .then(res => res.json())
      .then(data => {
        setBookFile(data?.key)
        setUploadStatus(false)
      })
  }

  const handleAuthorImageChange = e => {
    setUploadStatus(true)
    uploadFile(e)
      .then(res => res.json())
      .then(data => {
        setAuthorImage(data?.key)
        setUploadStatus(false)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Qlearn </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Book")}
            breadcrumbItem={props.t("Update book")}
          />

          <Row>
            <Col xl="12">
              <Row>
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardBody>
                        <Form onSubmit={handleSubmit}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                              Book Name
                            </Label>
                            <Input
                              value={bookName}
                              onChange={e => setBookName(e.target.value)}
                              type="text"
                              className="form-control"
                              id="formrow-firstname-Input"
                              required
                              placeholder="You Can Win"
                            />
                          </div>
                          <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                              Book Short Details
                            </Label>
                            <Input
                              value={bookShortDetails}
                              onChange={e =>
                                setBookShortDetails(e.target.value)
                              }
                              required
                              type="textarea"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="Write short brief about book..."
                            />
                          </div>
                          <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                              Book Details
                            </Label>
                            <Input
                              value={bookDetails}
                              required
                              onChange={e => setBookDetails(e.target.value)}
                              type="textarea"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="Write something about book..."
                            />
                          </div>
                          <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                              Author Name
                            </Label>
                            <Input
                              value={writerName}
                              onChange={e => setWriterName(e.target.value)}
                              required
                              type="text"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="Mr. XYZ"
                            />
                          </div>
                          <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                              Author Details
                            </Label>
                            <Input
                              required
                              value={authorDetails}
                              onChange={e => setAuthorDetails(e.target.value)}
                              type="textarea"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="Write something about author..."
                            />
                          </div>

                          <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                              Author Image
                            </Label>
                            <Input
                              onChange={e => handleAuthorImageChange(e)}
                              type="file"
                              className="form-control"
                              id="formrow-firstname-Input"
                            />
                          </div>
                          <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                              Upload Cover Image
                            </Label>
                            <Input
                              onChange={e => handleBookCoverChange(e)}
                              type="file"
                              className="form-control"
                              id="formrow-firstname-Input"
                            />
                          </div>
                          <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                              Upload Book File
                            </Label>
                            <Input
                              onChange={e => handleBookFileChange(e)}
                              type="file"
                              className="form-control"
                              id="formrow-firstname-Input"
                            />
                          </div>
                          <div>
                            <button
                              disabled={uploadStatus ? true : false}
                              type="submit"
                              className="btn btn-primary w-md"
                            >
                              Update
                            </button>
                          </div>
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(EditBook)
