import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Table, Tbody, Th, Thead, Tr } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import {
    Button, Card,
    CardBody, CardSubtitle, CardTitle, Col, Container,
    Row
} from "reactstrap"

// React router dom
import { useHistory } from "react-router"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

const Category = props => {
  const history = useHistory()
  const [categories, setCategories] = useState([])

  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/api/course/category/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(res => res.json())
      .then(data => setCategories(data?.message))
      .catch(err => console.log(err))
  }, [])

  console.log(categories)

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Qlearn </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Category")}
            breadcrumbItem={props.t("Category")}
          />
          <Button
            onClick={() => history.push(`/category/add/new`)}
            color="primary"
            className="btn btn-primary "
          >
            Add New Category
          </Button>

          <Row>
            <Col xl="12">
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <CardTitle>All Categories </CardTitle>
                      <CardSubtitle className="mb-3">
                        total 3 Categories
                      </CardSubtitle>

                      <div className="table-rep-plugin">
                        <div
                          className="table-responsive mb-0"
                          data-pattern="priority-columns"
                        >
                          <Table
                            id="tech-companies-1"
                            className="table table-striped table-bordered"
                          >
                            <Thead>
                              <Tr>
                                <Th>Category Name</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {categories?.length > 0 &&
                                categories?.map(category => (
                                  <Tr>
                                    <Th>
                                      <span className="co-name">
                                        {category?.category_name}
                                      </span>
                                    </Th>
                                  </Tr>
                                ))}
                            </Tbody>
                          </Table>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Category)
