import React from "react"
import MetaTags from "react-meta-tags"
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import {
    Button, Card,
    CardBody, CardSubtitle, CardTitle, Col, Container,
    Row
} from "reactstrap"

// React router dom
import { useHistory } from "react-router"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

const Instructors = props => {
  const history = useHistory()
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Qlearn </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Instructors")}
            breadcrumbItem={props.t("Instructors")}
          />
          <Button
            onClick={() => history.push(`/instructors/add/new`)}
            color="primary"
            className="btn btn-primary "
          >
            Add New Instructor
          </Button>

          <Row>
            <Col xl="12">
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <CardTitle>All Instructors List </CardTitle>
                      <CardSubtitle className="mb-3">
                        total 5 instructors
                      </CardSubtitle>

                      <div className="table-rep-plugin">
                        <div
                          className="table-responsive mb-0"
                          data-pattern="priority-columns"
                        >
                          <Table
                            id="tech-companies-1"
                            className="table table-striped table-bordered"
                          >
                            <Thead>
                              <Tr>
                                <Th>Name</Th>
                                <Th data-priority="1">Email</Th>
                                <Th data-priority="3">Mobile Number</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              <Tr>
                                <Th>
                                  <span className="co-name">
                                    Syket Bhattachergee
                                  </span>
                                </Th>
                                <Td>syketb@gmail.com</Td>
                                <Td>01234567890</Td>
                              </Tr>
                              <Tr>
                                <Th>
                                  <span className="co-name">
                                    Sakif Khan Pranto
                                  </span>
                                </Th>
                                <Td>sakif@gmail.com</Td>
                                <Td>01234567890</Td>
                              </Tr>
                              <Tr>
                                <Th>
                                  <span className="co-name">Zabir Hossain</span>
                                </Th>
                                <Td>zabir@gmail.com</Td>
                                <Td>01234567890</Td>
                              </Tr>
                              <Tr>
                                <Th>
                                  <span className="co-name">Tanbir Rasel</span>
                                </Th>
                                <Td>tanbir@gmail.com</Td>
                                <Td>01234567890</Td>
                              </Tr>
                              <Tr>
                                <Th>
                                  <span className="co-name">Anik Rifat</span>
                                </Th>
                                <Td>anik@gmail.com</Td>
                                <Td>01234567890</Td>
                              </Tr>
                            </Tbody>
                          </Table>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Instructors)
