/**
 * * Global File Upload
 */

export const fileUpload = async e => {
  const formData = new FormData()
  formData.append("image", e.target.files[0])

  return await fetch(`${process.env.REACT_APP_URL}/api/image/upload`, {
    method: "POST",
    body: formData,
  }).then(res => {
    return res
  })
}
