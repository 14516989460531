import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import {
    Button, Card,
    CardBody, CardSubtitle, CardTitle, Col, Container,
    Row
} from "reactstrap"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
// React router dom
import { useHistory } from "react-router"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

const Book = props => {
  const history = useHistory()
  const [books, setBooks] = useState([])
  const [status, setStatus] = useState(false)

  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/api/book/getall`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(res => res.json())
      .then(data => {
        setBooks(data?.message)
        setStatus(false)
      })
      .catch(err => console.log(err))
  }, [status])

  console.log(books)

  // delete specific course

  const handleDelete = bookId => {
    fetch(`${process.env.REACT_APP_URL}/api/book/delete/${bookId}`, {
      method: "DELETE",
    })
      .then(res => res.json())
      .then(data => {
        toastr.success("Course Deleted!!", "Course")
        setStatus(true)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Qlearn </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Book")}
            breadcrumbItem={props.t("Book")}
          />
          <Button
            onClick={() => history.push(`/book/add/new`)}
            color="primary"
            className="btn btn-primary "
          >
            Add New Book
          </Button>

          <Row>
            <Col xl="12">
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <CardTitle>All Books </CardTitle>
                      <CardSubtitle className="mb-3">
                        total 3 books
                      </CardSubtitle>

                      <div className="table-rep-plugin">
                        <div
                          className="table-responsive mb-0"
                          data-pattern="priority-columns"
                        >
                          <Table
                            id="tech-companies-1"
                            className="table table-striped table-bordered"
                          >
                            <Thead>
                              <Tr>
                                <Th>Book Name</Th>
                                <Th>Book Short Details</Th>
                                <Th>Author Name</Th>
                                <Th>Book Cover Image</Th>
                                <Th>Book File</Th>
                                <Th>Action</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {books?.length > 0 &&
                                books?.map(book => (
                                  <Tr>
                                    <Td>{book?.book_name}</Td>
                                    <Td>{book?.book_short_details}</Td>
                                    <Td>{book?.author_name}</Td>
                                    <Td>
                                      <img
                                        style={{
                                          width: "100px",
                                          height: "60px",
                                        }}
                                        src={`${process.env.REACT_APP_URL}/api/book/download/${book?.book_cover}`}
                                        alt=""
                                      />
                                    </Td>
                                    <Td>
                                      <a
                                        href={`${process.env.REACT_APP_URL}/api/book/download/${book?.book_file}`}
                                        target="_blank"
                                        download
                                      >
                                        Download
                                      </a>
                                    </Td>
                                    <Td>
                                      <button
                                        style={{ marginRight: "10px" }}
                                        onClick={() =>
                                          history.push(
                                            `/book/edit/${book?._id}`
                                          )
                                        }
                                        className="btn btn-primary w-md"
                                      >
                                        Edit
                                      </button>
                                      <button
                                        onClick={() => handleDelete(book?._id)}
                                        className="btn btn-danger w-md"
                                      >
                                        Delete
                                      </button>
                                    </Td>
                                  </Tr>
                                ))}
                            </Tbody>
                          </Table>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Book)
