import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
    Alert, Button, Card, CardBody, Col, Container, Media, Row
} from "reactstrap"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"

//redux
import { connect, useSelector } from "react-redux"

import { withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import avatar from "../../assets/images/user.png"
// actions
import { getCurrentUser } from "actions/auth"
import { fileUpload } from "actions/imageUpload"
import { updateProfile } from "actions/profile"

const UserProfile = ({ getCurrentUser }) => {
  const { error, success } = useSelector(state => ({
    error: state.Profile.error,
    success: state.Profile.success,
  }))

  const [currentUser, setCurrentUser] = useState(null)
  const [fullName, setFullName] = useState("")
  const [email, setEmail] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [shortDetails, setShortDetails] = useState("")
  const [image, setImage] = useState(null)
  const [facebookUrl, setFacebookUrl] = useState("")
  const [youtubeUrl, setYoutubeUrl] = useState("")
  const [idx, setidx] = useState(1)
  const [status, setStatus] = useState(false)

  const stringifyUser = localStorage.getItem("authUser")
  const authUser = stringifyUser !== null ? JSON.parse(stringifyUser) : null

  useEffect(() => {
    if (authUser) {
      setCurrentUser(authUser)
    }
  }, [])

  console.log({ currentUser })

  useEffect(() => {
    setFullName(currentUser ? currentUser?.fullName : "")
    setEmail(currentUser ? currentUser?.email : "")
    setPhoneNumber(currentUser ? currentUser?.phoneNumber : "")
    setShortDetails(currentUser ? currentUser?.short_details : "")
    setImage(currentUser ? currentUser?.image : null)
    setFacebookUrl(currentUser ? currentUser?.facebookUrl : "")
    setYoutubeUrl(currentUser ? currentUser?.youtubeUrl : "")
  }, [currentUser])

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      setFullName(obj.displayName)
      setEmail(obj.email)
      setidx(obj.uid)
    }
  }, [])

  function handleValidSubmit(event, values) {
    const data = {
      fullName: fullName,
      phoneNumber: phoneNumber,
      short_details: shortDetails,
      image,
      facebookUrl,
      youtubeUrl,
    }

    console.log(JSON.stringify(data))

    updateProfile(authUser?._id, data)
      .then(r => r.json())
      .then(res => {
        console.log(res)
        if (res?.success) {
          toastr.success(res?.message)
          getCurrentUser(authUser?._id)
        } else if (!res?.success) {
          toaster.error(res?.message)
        }
      })
      .catch(err => console.log(err))
  }

  const handleProfileImageUpload = e => {
    setStatus(true)
    fileUpload(e)
      .then(res => res.json())
      .then(data => {
        setImage(data?.key)
        setStatus(false)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Profile | Qlearn Instructor</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Skote" breadcrumbItem="Profile" />

          <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null}

              <Card>
                <CardBody>
                  <Media>
                    <div className="ms-3">
                      <img
                        src={
                          image
                            ? `${process.env.REACT_APP_URL}/api/book/download/${image}`
                            : avatar
                        }
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <Media body className="align-self-center">
                      <div className="text-muted">
                        <h5>{fullName}</h5>
                        <p className="mb-1">{email}</p>
                      </div>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Update Profile</h4>

          <Card>
            <CardBody>
              <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v)
                }}
              >
                <div className="m-3 text-center">
                  <img
                    src={
                      image
                        ? `${process.env.REACT_APP_URL}/api/book/download/${image}`
                        : avatar
                    }
                    alt=""
                    className="avatar-xl rounded-circle img-thumbnail"
                  />
                </div>
                <div className="text-center">
                  <input
                    type="file"
                    onChange={e => handleProfileImageUpload(e)}
                    style={{ display: "none" }}
                    id="image1"
                  />
                  <label htmlFor="image1">
                    <span className="btn btn-light  w-sm">
                      <i className="mdi mdi-upload  font-size-16"></i> Upload
                    </span>
                  </label>
                </div>

                <div className="form-group mb-3">
                  <AvField
                    name="fullName"
                    label="Full Name"
                    value={fullName}
                    onChange={e => setFullName(e.target.value)}
                    className="form-control"
                    placeholder="Enter Full Name"
                    type="text"
                    required
                  />
                  <AvField name="idx" value={idx} type="hidden" />
                </div>

                <div className="form-group mb-3">
                  <AvField
                    name="phoneNumber"
                    label="Mobile Number"
                    className="form-control"
                    placeholder="Write your mobile number"
                    type="number"
                    required
                    value={phoneNumber}
                    onChange={e => setPhoneNumber(e.target.value)}
                    maxLength={11}
                    minLength={11}
                  />
                  <AvField name="idx" value={idx} type="hidden" />
                </div>

                <div className="form-group mb-3">
                  <AvField
                    name="shortDetails"
                    label="Short Details"
                    value={shortDetails}
                    onChange={e => setShortDetails(e.target.value)}
                    className="form-control"
                    placeholder="Write something about your"
                    type="text"
                    required
                  />
                  <AvField name="idx" value={idx} type="hidden" />
                </div>

                <div className="form-group mb-3">
                  <AvField
                    name="facebookUrl"
                    label="Social Links [Facebook]"
                    value={facebookUrl}
                    onChange={e => setFacebookUrl(e.target.value)}
                    className="form-control"
                    placeholder="Facebook Link"
                    type="text"
                    required
                  />
                  <AvField name="idx" value={idx} type="hidden" />
                </div>
                <div className="form-group mb-3">
                  <AvField
                    name="youtubeUrl"
                    label="Social Links [Youtube]"
                    value={youtubeUrl}
                    onChange={e => setYoutubeUrl(e.target.value)}
                    className="form-control"
                    placeholder="Youtube Link"
                    type="text"
                    required
                  />
                  <AvField name="idx" value={idx} type="hidden" />
                </div>

                <div className="text-center mt-4">
                  <Button
                    style={{ backgroundColor: status ? "gray" : "#21d093" }}
                    disabled={status ? true : false}
                    type="submit"
                  >
                    {status ? "Uploading..." : "Update"}
                  </Button>
                </div>
              </AvForm>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

UserProfile.prototype = {
  getCurrentUser: PropTypes.func.isRequired,
}

export default connect(null, { getCurrentUser })(withRouter(UserProfile))
