import React from "react"
import MetaTags from "react-meta-tags"
import { Card, CardBody, Col, Container, Row } from "reactstrap"

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"

//redux

import { Link, useHistory } from "react-router-dom"

// import images
import { instructorSignup } from "actions/auth"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import logoImg from "../../assets/images/Favicon_Qlearn-01.png"
import profileImg from "../../assets/images/profile-img.png"

const Register = props => {
  const history = useHistory()

  // handleValidSubmit
  const handleValidSubmit = values => {
    console.log({ values })
    instructorSignup(values)
      .then(res => res.json())
      .then(data => {
        console.log(data)
        if (!data?.success) {
          toastr.error(data?.message, "Signup")
        } else if (data?.success) {
          toastr.success(data?.message, "Signup")
          history.push("/login")
        }
      })
      .catch(err => console.log(err?.response?.body))
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Register | Qlearn Instructor</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">
                          Creat an Instructor account
                        </h5>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoImg}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(v)
                      }}
                    >
                      <div className="mb-3">
                        <AvField
                          name="fullName"
                          label="Full Name"
                          type="text"
                          required
                          placeholder="Enter username"
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          id="email"
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="Password"
                          type="password"
                          required
                          placeholder="Enter Password"
                        />
                      </div>

                      <div className="mt-4">
                        <button
                          className="btn btn-primary btn-block "
                          type="submit"
                        >
                          Register
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          By registering you agree to the Qlearn{" "}
                          <a
                            href="https://qlearn.com.bd/terms&conditions"
                            rel="noreferrer"
                            target="_blank"
                          >
                            Terms & conditions
                          </a> 
                        </p>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {" "}
                    Login
                  </Link>{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Register
