import React, { useState, useEffect } from "react"
import CurriculumLesson from "./CurriculumLesson"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

function CurriculumSection({
  addStatus,
  setAddStatus,
  courseId,
  sec,
  handleDeleteSection,
}) {
  const [expand, setExpand] = useState(true)
  const [lessons, setLessons] = useState([])
  const [lessonName, setLessonName] = useState("")

  const [sectionDetails, setSectionDetails] = useState(null)

  // Get the section details

  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/api/chapter/get/${sec} `, {
      method: "GET",
    })
      .then(res => res.json())
      .then(data => setSectionDetails(data?.message))
      .catch(err => console.log(err))
  }, [sec, addStatus])

  // Get all lesson under this section

  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/api/lesson/getall/${sec}`, {
      method: "GET",
    })
      .then(res => res.json())
      .then(data => setLessons(data?.message?.lessons))
      .catch(err => console.log(err))
  }, [sec, addStatus])

  const handleSubmit = e => {
    e.preventDefault()

    const body = {
      lesson_title: lessonName,
      lesson_duration: "",
      lesson_url: "",
      lesson_description: "",
      lesson_attatchment: "",
    }
    fetch(`${process.env.REACT_APP_URL}/api/lesson/update/${sec}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then(res => res.json())
      .then(data => {
        toastr.success(data?.message, "Lesson")
        setAddStatus(true)
      })
      .catch(err => console.log(err))

    // setLessons(prevSection => [...prevSection, lessonName])
    setLessonName("")
  }

  /**
   * * Lesson View
   */

  const handleViewLesson = () => {
    //
  }
  /**
   * * Lesson Edit
   */

  const handleEditLesson = () => {
    //
  }

  /**
   *
   * * Delete Lesson
   */
  const handleDeleteLesson = lessonId => {
    fetch(`${process.env.REACT_APP_URL}/api/lesson/delete/${sec}/${lessonId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(res => res.json())
      .then(data => {
        toastr.success(data?.message, "Lesson")
        setAddStatus(true)
      })
  }

  return (
    <div className="curriculum__section__wrapper">
      <div
        onClick={() => setExpand(!expand)}
        className="curriculum__section__title"
      >
        <div className="hambar__title">
          <span className="hambar">
            <i className="bx bx-menu"></i>
          </span>
          <span className="title">{sectionDetails?.chapter_title}</span>
        </div>
        <span className="down">
          <i className="bx bx-chevron-down"></i>
        </span>
      </div>
      {expand && (
        <div className="lesson">
          {lessons?.length > 0 &&
            lessons?.map((les, i) => (
              <CurriculumLesson
                handleViewLesson={handleViewLesson}
                handleDeleteLesson={handleDeleteLesson}
                key={i}
                les={les}
                sectionId={sec}
                addStatus={addStatus}
                setAddStatus={setAddStatus}
              />
            ))}

          <form onSubmit={handleSubmit}>
            <div className="lesson__input">
              <span>
                <i className="bx bx-plus"></i>
              </span>
              <input
                type="text"
                value={lessonName}
                onChange={e => setLessonName(e.target.value)}
                placeholder="Create new lesson"
              />
            </div>
          </form>
          <div style={{ textAlign: "right", marginTop: "10px" }}>
            <span
              onClick={() => handleDeleteSection(sec)}
              className="section__delete"
            >
              Delete
            </span>
          </div>
        </div>
      )}
    </div>
  )
}

export default CurriculumSection
