import axios from "axios"
/**
 * * This is for the auth functionality
 * * Admin Login
 * * Admin Registration
 */

/**
 * * Get Current User
 */

export const getCurrentUser = async id => {
  console.log(id)

  await fetch(`${process.env.REACT_APP_URL}/api/instructor/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then(res => res.json())
    .then(data => {
      console.log(data)
      if (data?.success) {
        localStorage.setItem("authUser", JSON.stringify(data?.message))
      }
    })
}

/**
 * * Admin Login
 */

export const instructorSignup = async content => {
  return await fetch(
    `${process.env.REACT_APP_URL}/api/auth/signup/instructor`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(content),
    }
  ).then(res => {
    return res
  })
}

/**
 * * Admin Login
 */

export const instructorSignin = async content => {
  return await fetch(
    `${process.env.REACT_APP_URL}/api/auth/signin/instructor`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(content),
    }
  ).then(res => {
    return res
  })
}

/**
 * * Get Student Refresh Token
 */

export const adminRefreshToken = async content => {
  const config = {
    "Content-Type": "application/json",
    Accept: "application/json",
  }
  const body = content
  return await axios.post(
    `${process.env.REACT_APP_URL}/api/auth/refreshtoken/student`,
    body,
    config
  )
}

/**
 * * Student Logged out
 */

export const logOut = () => {
  localStorage.removeItem("auth_refresh_token")
  localStorage.removeItem("auth_access_token")
}

//** Forgot Password Mail send endpoint */
export const forgotPassword = async email => {
  return fetch(`${process.env.REACT_APP_URL}/api/instructor/password-forget`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ email }),
  }).then(res => {
    return res
  })
}

//** Forgot Password Mail send endpoint */
export const authResetPassword = async (email, code, newPassword) => {
  return fetch(`${process.env.REACT_APP_URL}/api/instructor/password-reset`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ email, code, newPassword }),
  }).then(res => {
    return res
  })
}
