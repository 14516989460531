import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import { map } from "lodash"
import * as moment from "moment"
import { Col, Container, Row, Table, Button } from "reactstrap"
import renderHTML from "react-render-html"
import "./course.scss"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import Image
import { useHistory } from "react-router-dom"
import companies from "assets/images/companies"

const CourseList = props => {
  const [courseList, setCourseList] = useState([])
  const [coursesId, setCoursesId] = useState([])

  const history = useHistory()

  const stringifyUser = localStorage.getItem("authUser")
  const authUser = stringifyUser !== null ? JSON.parse(stringifyUser) : null

  useEffect(() => {
    if (authUser !== null) {
      fetch(
        `${process.env.REACT_APP_URL}/api/instructor/course/${authUser?._id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then(response => {
          return response.json()
        })
        .then(data => {
          console.log(data)
          if (data?.success) {
            setCoursesId(data?.message?.course)
          }
        })
    }
  }, [])

  console.log({ coursesId })

  useEffect(() => {
    if (coursesId?.length > 0) {
      coursesId?.map(course => {
        fetch(`${process.env.REACT_APP_URL}/api/course/get/${course}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then(res => res.json())
          .then(data => {
            console.log(data)
            setCourseList(prevCourse => [...prevCourse, data?.message])
          })
      })
    }
  }, [coursesId])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Qlearn</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Courses" breadcrumbItem="Course List" />
          {/* <Button
            onClick={() => history.push(`/courses/add/new`)}
            color="primary"
            className="btn btn-primary "
          >
            Add New Course
          </Button> */}
          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive">
                  <Table className="project-list-table table-nowrap align-middle table-borderless">
                    <thead>
                      <tr>
                        <th scope="col" style={{ width: "100px" }}>
                          #
                        </th>
                        <th scope="col">Course Name</th>
                        <th scope="col">Publish Date</th>
                        <th scope="col">Total Enrolled</th>
                        <th scope="col">Instructor Name</th>
                        {/* <th scope="col">Action</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {map(courseList, (course, index) => (
                        <tr key={index}>
                          <td>
                            <img
                              src={
                                course?.upload_featured_image
                                  ? `${process.env.REACT_APP_URL}/api/image/download/${course?.upload_featured_image}`
                                  : ""
                              }
                              alt=""
                              className="avatar-sm"
                            />
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14">
                              <span className="text-dark">{course?.title}</span>
                            </h5>
                            <p className="text-muted mb-0">
                              {course?.course_description?.length > 0 &&
                                renderHTML(
                                  course?.course_description?.substr(0, 30)
                                )}
                            </p>
                          </td>
                          <td> {moment(course?.createdAt).format("lll")}</td>
                          <td>{course?.enrollement_count} students</td>
                          <td>
                            <span>{authUser?.fullName}</span>
                          </td>
                          {/* <td>
                            <button
                              style={{ marginRight: "10px" }}
                              onClick={() =>
                                history.push(`/courses/edit/${course?._id}`)
                              }
                              className="btn btn-primary w-md"
                            >
                              Edit
                            </button>
                            <button
                              onClick={() => handleDelete(course?._id)}
                              className="btn btn-danger w-md"
                            >
                              Delete
                            </button>
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>

          {/* <Row>
            <Col xs="12">
              <div className="text-center my-3">
                <Link to="#" className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                  Load more
                </Link>
              </div>
            </Col>
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(CourseList)
