export const updateProfile = async (id, values) => {
  return await fetch(
    `${process.env.REACT_APP_URL}/api/instructor/update/${id}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    }
  ).then(res => {
    return res
  })
}
