import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Card, CardBody, Col, Container, Media, Row } from "reactstrap"

//import action
import { getChartsData as onGetChartsData } from "../../store/actions"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { getAllStudents } from "actions/students"
import { useDispatch, useSelector } from "react-redux"

const Dashboard = props => {
  const [courses, setCourses] = useState([])
  const [students, setStudents] = useState([])

  const [courseList, setCourseList] = useState([])
  const [coursesId, setCoursesId] = useState([])

  const stringifyUser = localStorage.getItem("authUser")
  const authUser = stringifyUser !== null ? JSON.parse(stringifyUser) : null

  useEffect(() => {
    if (authUser !== null) {
      fetch(
        `${process.env.REACT_APP_URL}/api/instructor/course/${authUser?._id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then(response => {
          return response.json()
        })
        .then(data => {
          console.log(data)
          if (data?.success) {
            setCoursesId(data?.message?.course)
          }
        })
    }
  }, [])

  useEffect(() => {
    if (coursesId?.length > 0) {
      coursesId?.map(course => {
        fetch(`${process.env.REACT_APP_URL}/api/course/get/${course}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then(res => res.json())
          .then(data => {
            console.log(data)
            setCourseList(prevCourse => [...prevCourse, data?.message])
          })
      })
    }
  }, [coursesId])

  // Get all students
  useEffect(() => {
    if (authUser) {
      getAllStudents(authUser?._id)
        .then(r => r.json())
        .then(data => {
          setStudents(data?.message?.docs)
        })
        .catch(err => console.log(err))
    }
  }, [])

  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData,
  }))

  const reports = [
    {
      title: "Students",
      iconClass: "bx bx-user-plus",
      description: students?.length,
    },
    {
      title: "Courses",
      iconClass: "bx bx-book",
      description: courseList?.length,
    },
  ]

  const [periodData, setPeriodData] = useState([])

  useEffect(() => {
    setPeriodData(chartsData)
  }, [chartsData])

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(onGetChartsData("yearly"))
  }, [dispatch])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Qlearn </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />

          <Row>
            <Col xl="12">
              <Row>
                {/* Reports Render */}
                {reports.map((report, key) => (
                  <Col md="4" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <Media>
                          <Media body>
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </Media>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </Media>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
