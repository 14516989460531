import React, { useState, useEffect } from "react"
import { Modal, Card, CardBody, Row, Col, Label, Input } from "reactstrap"
import { UncontrolledTooltip } from "reactstrap"
import ReactQuil from "react-quill"
import "react-quill/dist/quill.snow.css"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

function CurriculumLesson({
  addStatus,
  setAddStatus,
  sectionId,
  les,
  handleDeleteLesson,
}) {
  const [view, setView] = useState(false)
  const [edit, setEdit] = useState(false)
  const [title, setTitle] = useState("")
  const [details, setDetails] = useState(null)
  const [links, setLinks] = useState(null)
  const [attachment, setAttachment] = useState(null)

  // Lesson Details
  const [lessonDetails, setLessonDetails] = useState(null)

  const handleBodyChange = e => {
    setDetails(e)
  }

  console.log(lessonDetails)

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_URL}/api/lesson/get/${sectionId}/${les?._id}`,
      {
        method: "GET",
      }
    )
      .then(res => res.json())
      .then(data => setLessonDetails(data?.message?.lessons[0]))
      .catch(err => console.log(err))
  }, [sectionId, les, addStatus])

  useEffect(() => {
    setTitle(lessonDetails?.title)
    setDetails(lessonDetails?.description ? lessonDetails?.description : "")
    setLinks(lessonDetails?.url)
    setAttachment(lessonDetails?.attatchment)
  }, [lessonDetails])

  const handleLessonUpdate = () => {
    console.log({ title, details, links, attachment })

    const body = {
      lesson_title: title,
      lesson_duration: "",
      lesson_url: links,
      lesson_description: details,
      lesson_attatchment: attachment,
    }
    fetch(
      `${process.env.REACT_APP_URL}/api/lesson/update/${sectionId}/${les?._id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    )
      .then(res => res.json())
      .then(data => {
        toastr.success(data?.message, "Lesson")
        setAddStatus(true)
      })
      .catch(err => console.log(err))

    setEdit(false)
  }

  return (
    <div className="curriculum__section__wrapper">
      <div className="curriculum__section__title">
        <div className="hambar__title">
          <span className="hambar">
            <i className="bx bx-menu"></i>
          </span>
          <span className="title">{les?.title}</span>
        </div>
        <div style={{ display: "flex", marginRight: "20px" }}>
          <div onClick={() => setEdit(true)} className="flex-fill">
            <span
              style={{ marginLeft: "10px", fontSize: "20px" }}
              id={"edit" + les?._id}
            >
              <i className="bx bxs-edit"></i>
              <UncontrolledTooltip placement="top" target={"edit" + les?._id}>
                Edit
              </UncontrolledTooltip>
            </span>
          </div>
          <div onClick={() => setView(true)} className="flex-fill">
            <span
              style={{ marginLeft: "10px", fontSize: "20px" }}
              id={"view" + les?._id}
            >
              <i className="bx bxs-show"></i>
              <UncontrolledTooltip placement="top" target={"view" + les?._id}>
                View
              </UncontrolledTooltip>
            </span>
          </div>

          <div className="flex-fill">
            <span
              onClick={() => handleDeleteLesson(les?._id)}
              style={{ marginLeft: "10px", fontSize: "20px" }}
              id={"delete" + les?._id}
            >
              <i className="bx bx-trash"></i>
              <UncontrolledTooltip placement="top" target={"delete" + les?._id}>
                Delete
              </UncontrolledTooltip>
            </span>
          </div>
        </div>
      </div>

      {/* Edit Lesson */}

      <Modal size="lg" isOpen={edit}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">
            {les?.title}
          </h5>
          <button
            onClick={() => {
              setEdit(false)
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Card>
              <CardBody>
                <div className="mb-3">
                  <Label htmlFor="formrow-firstname-Input">Title</Label>
                  <Input
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                    type="text"
                    className="form-control"
                    id="formrow-firstname-Input"
                    placeholder="Course Title"
                  />
                </div>
                <Row>
                  <Col
                    lg={12}
                    style={{ marginTop: "0px", marginBottom: "10px" }}
                  >
                    <ReactQuil
                      modules={CurriculumLesson.modules}
                      formats={CurriculumLesson.formats}
                      value={details}
                      onChange={e => handleBodyChange(e)}
                      placeholder="Write something amazing..."
                    />
                  </Col>
                </Row>
                <Row>
                  <div className="mt-3">
                    <Label>Media</Label>

                    <Input
                      type="textarea"
                      id="textarea"
                      value={links}
                      onChange={e => setLinks(e.target.value)}
                      placeholder="Add an embed link"
                    />
                  </div>
                </Row>
                <Row>
                  <div className="mt-3">
                    <Label>Attachment</Label>

                    <Input
                      type="textarea"
                      id="textarea"
                      value={attachment}
                      onChange={e => setAttachment(e.target.value)}
                      placeholder="Add an embed link like video, PDF, slider..."
                    />
                  </div>
                </Row>
                <div onClick={handleLessonUpdate} style={{ marginTop: "20px" }}>
                  <button className="btn btn-primary w-md">Save</button>
                </div>
              </CardBody>
            </Card>
          </Row>
        </div>
      </Modal>

      {/* View Lesson */}

      <Modal size="lg" isOpen={view}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">
            {les?.title}
          </h5>
          <button
            onClick={() => {
              setView(false)
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Card>
              <CardBody>
                <div className="mb-3">
                  <Label htmlFor="formrow-firstname-Input">Title</Label>
                  <Input
                    value={title}
                    readOnly
                    type="text"
                    className="form-control"
                    id="formrow-firstname-Input"
                    placeholder="Course Title"
                  />
                </div>
                <Row>
                  <Col
                    lg={12}
                    style={{ marginTop: "0px", marginBottom: "10px" }}
                  >
                    <ReactQuil
                      modules={CurriculumLesson.modules}
                      formats={CurriculumLesson.formats}
                      value={details}
                      readOnly={true}
                      placeholder="Write something amazing..."
                    />
                  </Col>
                </Row>
                <Row>
                  <div className="mt-3">
                    <Label>Media</Label>

                    <Input
                      type="textarea"
                      id="textarea"
                      value={links}
                      readOnly
                      placeholder="Add an embed link"
                    />
                  </div>
                </Row>
                <Row>
                  <div className="mt-3">
                    <Label>Attachment</Label>

                    <Input
                      type="textarea"
                      id="textarea"
                      value={attachment}
                      readOnly
                      placeholder="Add an embed link like video, PDF, slider..."
                    />
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Row>
        </div>
      </Modal>
    </div>
  )
}

CurriculumLesson.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { header: [3, 4, 5, 6] }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image", "video"],
    ["clean"],
    ["code-block"],
  ],
}

CurriculumLesson.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "link",
  "image",
  "video",
  "code-block",
]

export default CurriculumLesson
