import React, { useEffect, useState } from "react"
import Dropzone from "react-dropzone"
import MetaTags from "react-meta-tags"
import Select from "react-select"
import {
    Card, CardBody, Col,
    Container, Input, Label, Row
} from "reactstrap"

// Form Editor
import ReactQuil from "react-quill"
import "react-quill/dist/quill.snow.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

//redux

const AddNewCourse = props => {
  /**
   * * State for course data
   */
  const [title, setTitle] = useState("")
  const [fakeStudentEnrolled, setFakeStudentEnrolled] = useState(0)
  const [regularPrice, setRegularPrice] = useState(0)
  const [salePrice, setSalePrice] = useState(0)
  const [instructor, setInstructor] = useState("")
  const [thumbnailImage, setThumbnailImage] = useState([])
  const [details, setDetails] = useState(null)
  const [shortDescription, setShortDescription] = useState(null)
  const [paidCourse, setPaidCourse] = useState(true)
  const [liveClass, setLiveClass] = useState(true)
  const [textareabadge, settextareabadge] = useState(0)
  const [textcount, settextcount] = useState(0)
  const [allCategories, setAllCategories] = useState([])
  const [categories, setCategories] = useState([])
  const [categoryOption, setCategoryOption] = useState([])

  const [finalCategory, setFinalCategory] = useState([])

  const history = useHistory()

  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/api/course/category/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(res => res.json())
      .then(data => setAllCategories(data?.message))
      .catch(err => console.log(err))
  }, [])

  useEffect(() => {
    allCategories?.length > 0 &&
      allCategories?.map(category =>
        setCategoryOption(prevCategory => [
          ...prevCategory,
          { label: category?.category_name, value: category?._id },
        ])
      )
  }, [allCategories])

  useEffect(() => {
    categories?.length > 0 &&
      categories?.map(category => {
        setFinalCategory(prevCategory => [...prevCategory, category?.value])
      })
  }, [categories])

  const optionGroup = [
    {
      options: [
        { label: "Syket", value: "Syket" },
        { label: "Pranto", value: "Pranto" },
        { label: "Zabir", value: "Zabir" },
        { label: "Rasel", value: "Rasel" },
      ],
    },
  ]

  const categoryNames = [
    {
      options: categoryOption,
    },
  ]

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setThumbnailImage(files)
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const handleSubmit = async () => {
    const body = {
      title,
      course_description: details,
      catagory: "Programming",
      subject: "Language",
      category: [...new Set(finalCategory)],
      regular_price: Number(regularPrice),
      sale_price: Number(salePrice),
      enrollement_count: Number(fakeStudentEnrolled),
      tag: ["C", "programming"],
      course_paid: paidCourse,
      course_live: liveClass,
      course_short_description: shortDescription,
      upload_featured_image: "www.kerkjcndkekn",
    }

    await fetch(`${process.env.REACT_APP_URL}/api/course/post`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then(res => res?.json())
      .then(data => {
        toastr.success("Course Created!!", "Course")
        history.push("/courses")
      })
      .catch(err => console.log(err))
  }
  const handleBodyChange = e => {
    setDetails(e)
  }

  function textareachange(event) {
    setShortDescription(event.target.value)
    const count = event.target.value.length
    if (count > 0) {
      settextareabadge(true)
    } else {
      settextareabadge(false)
    }
    settextcount(event.target.value.length)
  }

  function handleMulti(e) {
    setCategories(e)
    setFinalCategory([])
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Qlearn </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Courses")}
            breadcrumbItem={props.t("Add new course")}
          />

          <Row>
            <Col xl="12">
              <Row>
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardBody>
                        <div>
                          <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                              Title
                            </Label>
                            <Input
                              value={title}
                              onChange={e => setTitle(e.target.value)}
                              type="text"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="Course Title"
                            />
                          </div>

                          <Row>
                            <Col
                              lg={12}
                              style={{ marginTop: "0px", marginBottom: "10px" }}
                            >
                              <ReactQuil
                                modules={AddNewCourse.modules}
                                formats={AddNewCourse.formats}
                                value={details}
                                onChange={e => handleBodyChange(e)}
                                placeholder="Write something amazing..."
                              />
                            </Col>
                          </Row>
                          <Row>
                            <div className="mt-2 mb-3">
                              <Label>Short Description</Label>

                              <Input
                                type="textarea"
                                id="textarea"
                                onChange={e => {
                                  textareachange(e)
                                }}
                                maxLength="225"
                                rows="3"
                                placeholder="Short description has a limit of 225 chars."
                              />
                              {textareabadge ? (
                                <span className="badgecount badge bg-success">
                                  {" "}
                                  {textcount} / 225{" "}
                                </span>
                              ) : null}
                            </div>
                          </Row>

                          <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                              Fake Student Enrolled
                            </Label>
                            <Input
                              value={fakeStudentEnrolled}
                              onChange={e =>
                                setFakeStudentEnrolled(e.target.value)
                              }
                              type="number"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="0"
                            />
                          </div>
                          <Row>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-email-Input">
                                  Regular Price
                                </Label>
                                <Input
                                  value={regularPrice}
                                  onChange={e =>
                                    setRegularPrice(e.target.value)
                                  }
                                  type="number"
                                  className="form-control"
                                  id="formrow-email-Input"
                                  placeholder="0"
                                />
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-password-Input">
                                  Sale Price
                                </Label>
                                <Input
                                  value={salePrice}
                                  onChange={e => setSalePrice(e.target.value)}
                                  type="number"
                                  className="form-control"
                                  id="formrow-password-Input"
                                  placeholder="0"
                                />
                              </div>
                            </Col>
                          </Row>

                          <div className="mb-3">
                            <Label>Instructors</Label>
                            <Select
                              value={instructor}
                              onChange={data => {
                                setInstructor(data)
                              }}
                              options={optionGroup}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="control-label">
                              Select Categories
                            </label>
                            <Select
                              value={categories}
                              isMulti={true}
                              onChange={e => {
                                handleMulti(e)
                              }}
                              options={categoryNames}
                              classNamePrefix="select2-selection"
                            />
                          </div>

                          <div>
                            <label htmlFor="">Upload Featured Image</label>
                            <Dropzone
                              onDrop={acceptedFiles => {
                                handleAcceptedFiles(acceptedFiles)
                              }}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div className="dropzone">
                                  <div
                                    className="dz-message needsclick mt-2"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    <div className="mb-3">
                                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                                    </div>
                                    <h4>Drop files here or click to upload.</h4>
                                  </div>
                                </div>
                              )}
                            </Dropzone>
                            <div
                              className="dropzone-previews mt-3"
                              id="file-previews"
                            >
                              {thumbnailImage.map((f, i) => {
                                return (
                                  <Card
                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                    key={i + "-file"}
                                  >
                                    <div className="p-2">
                                      <Row className="align-items-center">
                                        <Col className="col-auto">
                                          <img
                                            data-dz-thumbnail=""
                                            height="80"
                                            className="avatar-sm rounded bg-light"
                                            alt={f.name}
                                            src={f.preview}
                                          />
                                        </Col>
                                        <Col>
                                          <span className="text-muted font-weight-bold">
                                            {f.name}
                                          </span>
                                          <p className="mb-0">
                                            <strong>{f.formattedSize}</strong>
                                          </p>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Card>
                                )
                              })}
                            </div>
                          </div>
                          <div className="my-4">
                            <h5 className="font-size-14 mb-4">
                              Free/Paid Status
                            </h5>
                            <div className="form-check mb-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="free-paid-status"
                                id="free"
                                value="Free"
                                onChange={e => setPaidCourse(false)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="free"
                              >
                                Free
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="free-paid-status"
                                id="paid"
                                value="Paid"
                                onChange={e => setPaidCourse(true)}
                                defaultChecked
                              />
                              <label
                                className="form-check-label"
                                htmlFor="paid"
                              >
                                Paid
                              </label>
                            </div>
                          </div>
                          <div className="my-4">
                            <h5 className="font-size-14 mb-4">Class Status</h5>
                            <div className="form-check mb-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="class-status"
                                id="live"
                                value="Live"
                                onChange={e => setLiveClass(true)}
                                defaultChecked
                              />
                              <label
                                className="form-check-label"
                                htmlFor="live"
                              >
                                Live Class
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="class-status"
                                id="prerecord"
                                value="Prerecord"
                                onChange={e => setLiveClass(false)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="prerecord"
                              >
                                Prerecord Class
                              </label>
                            </div>
                          </div>

                          <div>
                            <button
                              onClick={handleSubmit}
                              className="btn btn-primary w-md"
                            >
                              Publish
                            </button>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

AddNewCourse.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { header: [3, 4, 5, 6] }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image", "video"],
    ["clean"],
    ["code-block"],
  ],
}

AddNewCourse.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "link",
  "image",
  "video",
  "code-block",
]

export default withTranslation()(AddNewCourse)
